import React, { useState } from "react"
import styled from "@emotion/styled";
import LeftArrowIconImg from "../../images/icons/left-arrow-icon.png";
import RightArrowIconImg from "../../images/icons/right-arrow-icon.png";
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby";
import ButtonOutline from "../buttonOutline";

const NavigationButton = styled.button`
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all .2s ease-in-out;
    margin: 1rem;

    img {
        width: 100%;
        max-width: 3rem;
    }

    @media (hover: hover) {
        :hover {
            transform: scale(1.1);
        }
    }
`;

const ProductContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 50%;

    @media (max-width: 60rem) {
        width: 100%;
    }
`;

const ProductImage = styled(Img)`
    width: 100%;
    height: 100%;
    max-width: 600px;
    max-height: 600px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 30rem;
    margin: 1rem 0;

    @media (max-width: 60rem) {
        background-color: rgb(0, 0, 0, 0.3);
        padding: 0.5rem;
    }
`;

const Heading = styled.h3`
    color: #fff;
    text-transform: uppercase;
    font-size: 2rem;
    margin-bottom: 0rem;
    text-align: center;
`;

const Copy = styled.p`
    font-size: 1.2rem;
    color: #fff;
    text-align: center;
`;

const ProductSection = () => {
    const data = useStaticQuery(
        graphql`
        query {
          cans: file(relativePath: { eq: "products/cans.png" }) {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          original: file(relativePath: { eq: "products/original.png" }) {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          vanilla: file(relativePath: { eq: "products/vanilla.png" }) {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          pineapple: file(relativePath: { eq: "products/pineapple.png" }) {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `
    )

    const cansImageData = data.cans.childImageSharp.fluid;
    const originalImageData = data.original.childImageSharp.fluid;
    const vanillaImageData = data.vanilla.childImageSharp.fluid;
    const pineappleImageData = data.pineapple.childImageSharp.fluid;

    const products = [
        {
            image: cansImageData,
            title: '',
            copy: '',
            link: '',
            c1: '#ffc43e',
            c2: '#f68507'
        },
        {
            image: vanillaImageData,
            title: 'Vanilla',
            copy: 'No matter the occasion or mood, you can indulge in the smoothness of Maltina Vanilla with its delicate malty note. Spoil yourself with its inviting aromas and light, foamy texture for a relaxing time with friends and family.',
            link: 'https://www.konga.com/product/maltina-vanilla-non-alcoholic-malt-drink-33cl-can-x-6-5092674',
            c1: '#65cbe7',
            c2: '#257dc2'
        },
        {
            image: originalImageData,
            title: 'Classic',
            copy: 'Maltina Classic is our original malt drink recipe specially brewed with malted barley and hops extract. It is packed full of essential Vitamins (A, B1, B2, B3, B5, B6 and C) & Minerals (Calcium) to nourish and keep you completely recharged. It is available in 33cl bottles, Cans & PET to be enjoyed at any occasion.',
            link: 'https://www.konga.com/product/maltina-non-alcoholic-malt-drink-33cl-can-x-24-4953029',
            c1: '#ffc43e',
            c2: '#f68507'
        },
        {
            image: pineappleImageData,
            title: 'Pineapple',
            copy: 'Let the good times roll in while enjoying the refreshing crisp taste of Maltina Pineapple with its tropical note. Savour the inviting fruity aroma that sends you on a sweet escape as you enjoy the chilled vibes on the go.',
            link: 'https://www.konga.com/product/maltina-pineapple-non-alcoholic-malt-drink-33cl-can-x-6-5092676',
            c1: '#a6cb70',
            c2: '#0aa049'
        }
    ];

    const [index, setIndex] = useState(0);
    const [product, setProduct] = useState(products[0]);

    const Section = styled.section`
        min-height: 100vh;
        background: radial-gradient(circle at center, ${product.c1} 20%, ${product.c2} 60%);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 5rem;

        @media (max-width: 60rem) {
            flex-direction: column;
            padding: 1rem;
        }
    `;

    const onLeftClick = () => {
        let newIndex = index;

        if (newIndex <= 0) {
            newIndex = products.length - 1;
        } else {
            newIndex--;
        }

        setIndex(newIndex);
        setProduct(products[newIndex]);
    }

    const onRightClick = () => {
        let newIndex = index;

        if (newIndex >= products.length - 1) {
            newIndex = 0;
        } else {
            newIndex++;
        }

        setIndex(newIndex);
        setProduct(products[newIndex]);
    }

    return (
        <Section id="products">
            <ProductContainer>
                <NavigationButton onClick={onLeftClick} aria-label="Previous Product">
                    <img src={LeftArrowIconImg} alt="" />
                </NavigationButton>
                <ProductImage fluid={product.image} alt="" imgStyle={{
                    objectFit: 'contain'
                }} />
                <NavigationButton onClick={onRightClick} aria-label="Next Product">
                    <img src={RightArrowIconImg} alt="" />
                </NavigationButton>
            </ProductContainer>
            {product.copy && <Content>
                <Heading>
                    {product.title}
                </Heading>
                <Copy>
                    {product.copy}
                </Copy>
                <ButtonOutline href={product.link}>
                    Buy Now
                </ButtonOutline>
            </Content>}
        </Section>
    )
}

export default ProductSection;
