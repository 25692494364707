import React from "react";
import styled from "@emotion/styled";
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby";
import Header from "../header";

const Section = styled.section`
    
`;

const Background = styled.div`
    min-height: 100vh;
    background: radial-gradient(circle at center, #ffc43e 20%, #f68507 60%);
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ProductImg = styled(Img)`
    height: calc(100vh - 4rem);
    width: 100%;
`;

const HeroSection = () => {
    const data = useStaticQuery(
        graphql`
        query {
          desktop: file(relativePath: { eq: "bottles.png" }) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `
    )

    const imageData = data.desktop.childImageSharp.fluid;

    return (
        <Section id="home">
            <Background>
                <Header />
                <ProductImg fluid={imageData} imgStyle={{
                    objectFit: "contain"
                }} />
            </Background>

        </Section>
    )
}

export default HeroSection;
