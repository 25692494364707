import React from "react";
import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import ButtonOutline from "../buttonOutline";

const Section = styled.section`

`;

const Background = styled(BackgroundImage)`
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding: 5rem;

    @media (max-width: 60rem) {
        padding: 1rem;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    max-width: 30rem;
    margin: 1rem 0;

    @media (max-width: 60rem) {
        align-items: center;
        background-color: rgb(0, 0, 0, 0.3);
        padding: 0.5rem;
    }
`;

const Heading = styled.h3`
    color: #fff;
    text-transform: uppercase;
    font-size: 2rem;
    margin-bottom: 0;
    text-align: right;

    @media (max-width: 60rem) {
        text-align: center;
    }
`;

const Copy = styled.p`
    font-size: 1.2rem;
    color: #fff;
    text-align: right;

    @media (max-width: 60rem) {
        text-align: center;
    }
`;

const SchoolGamesSection = () => {
    const data = useStaticQuery(
        graphql`
        query {
          background: file(relativePath: { eq: "sections/school-games.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `
    );

    const backgroundData = data.background.childImageSharp.fluid;

    return (
        <Section id="school-games">
            <Background fluid={backgroundData}>
                <Content>
                    <Heading>
                        Maltina School Games
                    </Heading>
                    <Copy>
                        We are all about supporting youth development particularly through sports. The Maltina School Games is the platform through which we support them as they develop, giving young athletes the required boost to take their rightful positions as the leaders of a better tomorrow.
                    </Copy>
                    <ButtonOutline href="/school-games">
                        Learn More
                    </ButtonOutline>
                </Content>
            </Background>
        </Section>
    )
}

export default SchoolGamesSection;
