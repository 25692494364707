import React from "react"
import { Global, css } from '@emotion/react'
import SEO from "../components/seo";
import HeroSection from "../components/sections/heroSection";
import BadgeSection from "../components/sections/badgeSection";
import AboutSection from "../components/sections/aboutSection";
import ProductSection from "../components/sections/productSection";
import OwnTheFlavourSection from "../components/sections/ownTheFlavourSection";
import SocialSection from "../components/sections/socialSection";
import ProjectsSection from "../components/sections/projectsSection";
import HappinessSection from "../components/sections/happinessSection";
import SchoolGamesSection from "../components/sections/schoolGamesSection";
import TeacherSection from "../components/sections/teacherSection";
import ContactSection from "../components/sections/contactSection";
import Footer from "../components/footer";

const globalCss = css`
    @font-face {
        font-family: 'Brandon';
        src: url('/fonts/Brandon_reg.otf') format('opentype');
        font-display: swap;
    }

    @font-face {
        font-family: 'Brandon';
        src: url('/fonts/Brandon_bld.otf') format('opentype');
        font-weight: bold;
        font-display: swap;
    }

  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    font-family: "Brandon";
    margin: 0;
    padding: 0;
  }
`;

const IndexPage = () => {
  return (
    <>
      <SEO title="Maltina" />
      <main>
        <Global styles={globalCss} />
        <HeroSection />
        <BadgeSection />
        <AboutSection />
        <ProductSection />
        <ProjectsSection />
        <OwnTheFlavourSection />
        <HappinessSection />
        <SchoolGamesSection />
        <TeacherSection />
        <SocialSection />
        <ContactSection />
        <Footer />
      </main>
    </>
  )
}

export default IndexPage
