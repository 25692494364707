import React from "react";
import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";

const Section = styled.section`

`;

const Background = styled(BackgroundImage)`
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 5rem;

    @media (max-width: 60rem) {
        padding: 1rem;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 30rem;
    margin: 1rem 0;

    @media (max-width: 60rem) {
        background-color: rgb(0, 0, 0, 0.3);
        padding: 0.5rem;
    }
`;

const Heading = styled.h3`
    color: #fff;
    text-transform: uppercase;
    font-size: 2rem;
    margin-bottom: 0rem;
    text-align: center;
`;

const Copy = styled.p`
    font-size: 1.2rem;
    color: #fff;
    text-align: center;
`;

const AboutSection = () => {
    const data = useStaticQuery(
        graphql`
        query {
          background: file(relativePath: { eq: "sections/about-us.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `
    );

    const backgroundData = data.background.childImageSharp.fluid;

    return (
        <Section id="about-us">
            <Background fluid={backgroundData}>
                <Content>
                    <Heading>
                        About Us
                    </Heading>
                    <Copy>
                        Maltina is the smooth, nourishing malt drink with complete richness. Launched as a good supplement, Maltina is fortified with Vitamins and Calcium for healthy families. Maltina remains the No. 1 Malt drink brand in Nigeria with its innovations and message of sharing happiness.
                    </Copy>
                </Content>
            </Background>
        </Section>
    )
}

export default AboutSection;
