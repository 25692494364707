import React from "react"
import styled from "@emotion/styled";
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby";

import FacebookIconDarkImg from "../../images/icons/facebook-icon-dark.png";
import InstagramIconDarkImg from "../../images/icons/instagram-icon-dark.png";
import TwitterIconDarkImg from "../../images/icons/twitter-icon-dark.png";
import YoutubeIconDarkImg from "../../images/icons/youtube-icon-dark.png";

const SocialMediaContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;

const SocialMediaButton = styled.button`
    background-color: transparent;
    outline: none;
    border: none;
    width: 5rem;
    height: 5rem;
    margin: 1rem;
    padding: 0;
    cursor: pointer;
    transition: all .2s ease-in-out;

    @media (hover: hover) {
        :hover {
            transform: scale(1.1);
        }
    }

    img {
        width: 100%;
    }

    @media (max-width: 60rem) {
        width: 3rem;
        height: 3rem;
        margin: 0.5rem;
    }
`;

const Section = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem;

    @media (max-width: 60rem) {
        padding: 1rem;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 60rem;
    margin: 1rem 0;
`;

const Heading = styled.h3`
    color: #f6861f;
    text-transform: uppercase;
    font-size: 2rem;
    margin: 0;
`;

const Copy = styled.p`
    font-size: 1.2rem;
    color: #000;
    text-align: center;
`;

const TileContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    @media (max-width: 60rem) {
        flex-direction: column;
    }
`;

const Tile = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: auto;
    margin: 1rem;
    cursor: pointer;
    transition: all .2s ease-in-out;
    text-decoration: none;

    @media (hover: hover) {
        :hover {
            transform: scale(1.02);
        }
    }

    @media (max-width: 60rem) {
        margin: 1rem 0;
        width: 100%;
    }
`;

const TileImage = styled(Img)`
    width: 100%;
`;

const SocialSection = () => {
    const imageData = useStaticQuery(
        graphql`
        query {
            social1: file(relativePath: { eq: "social/social-1.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
            }
            social2: file(relativePath: { eq: "social/social-2.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
            }
            social3: file(relativePath: { eq: "social/social-3.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
            }
            social4: file(relativePath: { eq: "social/social-4.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
            }
            social5: file(relativePath: { eq: "social/social-5.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
            }
            social6: file(relativePath: { eq: "social/social-6.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
            }
            social7: file(relativePath: { eq: "social/social-7.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
            }
            social8: file(relativePath: { eq: "social/social-8.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
            }
        }
      `
    )

    const social1ImageData = imageData.social1.childImageSharp.fluid;
    const social2ImageData = imageData.social2.childImageSharp.fluid;
    const social3ImageData = imageData.social3.childImageSharp.fluid;
    const social4ImageData = imageData.social4.childImageSharp.fluid;
    const social5ImageData = imageData.social5.childImageSharp.fluid;
    const social6ImageData = imageData.social6.childImageSharp.fluid;
    const social7ImageData = imageData.social7.childImageSharp.fluid;
    const social8ImageData = imageData.social8.childImageSharp.fluid;

    return (
        <Section id="social">
            <Content>
                <Heading>
                    Social Posts
                </Heading>
                <Copy>
                    See what the buzz is about by checking out our social media pages!
                </Copy>
            </Content>
            <TileContainer>
                <Tile href="https://www.instagram.com/maltina/" title="Social 1">
                    <TileImage fluid={social1ImageData} />
                </Tile>
                <Tile href="https://www.instagram.com/maltina/" title="Social 2">
                    <TileImage fluid={social2ImageData} />
                </Tile>
                <Tile href="https://www.instagram.com/maltina/" title="Social 3">
                    <TileImage fluid={social3ImageData} />
                </Tile>
                <Tile href="https://www.instagram.com/maltina/" title="Social 4">
                    <TileImage fluid={social4ImageData} />
                </Tile>
                <Tile href="https://www.instagram.com/maltina/" title="Social 5">
                    <TileImage fluid={social5ImageData} />
                </Tile>
                <Tile href="https://www.instagram.com/maltina/" title="Social 6">
                    <TileImage fluid={social6ImageData} />
                </Tile>
                <Tile href="https://www.instagram.com/maltina/" title="Social 7">
                    <TileImage fluid={social7ImageData} />
                </Tile>
                <Tile href="https://www.instagram.com/maltina/" title="Social 8">
                    <TileImage fluid={social8ImageData} />
                </Tile>
            </TileContainer>
            <SocialMediaContainer>
                <SocialMediaButton>
                    <a href="https://www.facebook.com/MaltinaNigeria/" target="_blank" rel="noreferrer" title="Facebook">
                        <img src={FacebookIconDarkImg} alt="Facebook" />
                    </a>
                </SocialMediaButton>
                <SocialMediaButton>
                    <a href="https://www.instagram.com/maltina/" target="_blank" rel="noreferrer" title="Instagram">
                        <img src={InstagramIconDarkImg} alt="Instagram" />
                    </a>
                </SocialMediaButton>
                <SocialMediaButton>
                    <a href="https://twitter.com/Maltina/" target="_blank" rel="noreferrer" title="Twitter">
                        <img src={TwitterIconDarkImg} alt="Twitter" />
                    </a>
                </SocialMediaButton>
                <SocialMediaButton>
                    <a href="https://www.youtube.com/channel/UCY2PTQACTRBmmzN7XxYxCYA/videos" target="_blank" rel="noreferrer" title="YouTube">
                        <img src={YoutubeIconDarkImg} alt="YouTube" />
                    </a>
                </SocialMediaButton>
            </SocialMediaContainer>
        </Section>
    )
}

export default SocialSection;
