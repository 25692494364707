import React from "react";
import styled from "@emotion/styled";
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby";
import ButtonOutline from "../buttonOutline";

const Section = styled.section`

`;

const Background = styled.div`
    background: rgb(59,176,226);
    background: radial-gradient(circle at center, rgba(255, 255, 255, 0.5) 20%, rgba(255,255,255,0) 60%), linear-gradient(90deg, rgba(59,176,226,1) 40%, rgba(127,198,89,1) 60%);
    min-height: 70vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 5rem;

    @media (max-width: 60rem) {
        padding: 1rem;
        flex-direction: column-reverse;
        justify-content: center;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    max-width: 30rem;
    margin: 1rem 0;

    @media (max-width: 60rem) {
        align-items: center;
    }
`;

const Heading = styled.h3`
    color: #fff;
    text-transform: uppercase;
    font-size: 2rem;
    margin-bottom: 0rem;
`;

const Copy = styled.p`
    font-size: 1.2rem;
    color: #fff;
    text-align: right;

    @media (max-width: 60rem) {
        text-align: center;
    }
`;

const ProductImage = styled(Img)`
    width: 100%;
    max-width: 800px;
`;

const OwnTheFlavourSection = () => {
    const data = useStaticQuery(
        graphql`
        query {
          desktop: file(relativePath: { eq: "own-the-flavour.png" }) {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `
    )

    const imageData = data.desktop.childImageSharp.fluid

    return (
        <Section id="tastepiration">
            <Background>
                <ProductImage fluid={imageData} />
                <Content>
                    <Heading>
                        Tastepiration
                    </Heading>
                    <Copy>
                        Find your taste, your vibe, and your feelin' with our exiting new flavours. Inspire your taste buds and discovery your fun side by mixing and matching with our Vanilla and Pineapple flavours to create your own perfect piece of art. Build your own Tastespiration!
                    </Copy>
                    <ButtonOutline href="https://www.owntheflavour.com/">
                        Learn More
                    </ButtonOutline>
                </Content>
            </Background>
        </Section>
    )
}

export default OwnTheFlavourSection;
