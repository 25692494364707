import React from "react"
import styled from "@emotion/styled";
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby";

const Section = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem;

    @media (max-width: 60rem) {
        padding: 1rem;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 60rem;
    margin: 1rem 0;
`;

const Heading = styled.h3`
    color: #f6861f;
    text-transform: uppercase;
    font-size: 2rem;
    margin: 0;
`;

const Copy = styled.p`
    font-size: 1.2rem;
    color: #000;
    text-align: center;
`;

const TileContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    @media (max-width: 60rem) {
        flex-direction: column;
    }
`;

const Tile = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 1rem;
    cursor: pointer;
    transition: all .2s ease-in-out;
    text-decoration: none;

    @media (hover: hover) {
        :hover {
            transform: scale(1.02);
        }
    }

    @media (max-width: 60rem) {
        margin: 1rem 0;
    }
`;

const TileImage = styled(Img)`
    width: 100%;
`;

const TileHeading = styled.h3`
    margin: 0;
    background-color: #f6861f;
    color: #fff;
    width: 100%;
    height: 5rem;
    line-height: 5rem;
    text-align: center;
    text-transform: uppercase;
`;

const ProjectsSection = () => {
    const data = useStaticQuery(
        graphql`
        query {
          project1: file(relativePath: { eq: "projects/project-1.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          project2: file(relativePath: { eq: "projects/project-2.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          project3: file(relativePath: { eq: "projects/project-3.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          project4: file(relativePath: { eq: "projects/project-4.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `
    )

    const project1ImageData = data.project1.childImageSharp.fluid;
    const project2ImageData = data.project2.childImageSharp.fluid;
    const project3ImageData = data.project3.childImageSharp.fluid;
    const project4ImageData = data.project4.childImageSharp.fluid;

    return (
        <Section id="projects">
            <Content>
                <Heading>
                    Our Projects
                </Heading>
                <Copy>
                    Our projects stay true to our core purpose: to develop and improve the quality of communities and help consumers appreciate the little moments of happiness.
                </Copy>
            </Content>
            <TileContainer>
                <Tile href="#tastepiration" title="Tastepiration">
                    <TileImage fluid={project1ImageData} />
                    <TileHeading>
                        Tastepiration
                    </TileHeading>
                </Tile>
                <Tile href="#happiness" title="Find your Happiness">
                    <TileImage fluid={project2ImageData} />
                    <TileHeading>
                        Find your Happiness
                    </TileHeading>
                </Tile>
                <Tile href="#school-games" title="Maltina School Games">
                    <TileImage fluid={project3ImageData} />
                    <TileHeading>
                        Maltina School Games
                    </TileHeading>
                </Tile>
                <Tile href="#teacher-of-the-year" title="Maltina Teacher of the Year">
                    <TileImage fluid={project4ImageData} />
                    <TileHeading>
                        Maltina Teacher of the Year
                    </TileHeading>
                </Tile>
            </TileContainer>
        </Section>
    )
}

export default ProjectsSection;
