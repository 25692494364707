import React from "react"
import styled from '@emotion/styled'
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby";

const Section = styled.section`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    padding: 4rem 2rem;

    @media (max-width: 60rem) {
        flex-direction: column;
        align-items: center;
    }
`;

const BadgeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;

    @media (max-width: 60rem) {
        margin-bottom: 2rem;
    }
`;

const BadgeHeading = styled.h3`
    margin: 1rem 0 0;
    font-weight: bold;
    font-size: 1.4rem;
`;

const BadgeImg = styled(Img)`
    width: 200px;
    animation: pulse 2s ease 0s infinite;

    @keyframes pulse {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.03);
        }
        100% {
            transform: scale(1);
        }
    }
`;

const BadgeCopy = styled.p`
    text-align: center;
    margin: 0;
    max-width: 20rem;
`;

const BadgeSection = () => {
    const imageData = useStaticQuery(
        graphql`
        query {
            malt: file(relativePath: { eq: "badges/delicious-malt-icon.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
            }
            bonds: file(relativePath: { eq: "badges/strong-bonds-icon.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
            }
            vitamin: file(relativePath: { eq: "badges/vitamin-icon.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
            }
        }
      `
    )

    const deliciousMaltImageData = imageData.malt.childImageSharp.fluid
    const strongBondsImageData = imageData.bonds.childImageSharp.fluid
    const vitaminImageData = imageData.vitamin.childImageSharp.fluid

    return (
        <Section>
            <BadgeContainer>
                <BadgeImg fluid={vitaminImageData} />
                <BadgeHeading>
                    Vitamin Enriched
                </BadgeHeading>
                <BadgeCopy>
                    Maltina is the only malt brand in Nigeria that is enriched with vitamins A, B1, B2, B3, B5, B6 and Calcium making it the perfect nourishing drink for the whole family. This refreshing and exciting malt is packed with essential minerals to keep your body and mind charged.
                </BadgeCopy>
            </BadgeContainer>

            <BadgeContainer>
                <BadgeImg fluid={deliciousMaltImageData} />
                <BadgeHeading>
                    Delicious Malt Goodness
                </BadgeHeading>
                <BadgeCopy>
                    It has delicious, rich and creamy taste that is great for everyone, anywhere and at anytime. Enjoy the refreshing malty notes of Maltina before, during or after meals. Perfect for relaxing after a long day; or while entertaining friends and family. Go on, share happiness!
                </BadgeCopy>
            </BadgeContainer>

            <BadgeContainer>
                <BadgeImg fluid={strongBondsImageData} />
                <BadgeHeading>
                    Building Strong Bonds
                </BadgeHeading>
                <BadgeCopy>
                    We are all about sharing happy moments and nurturing true bonds that last. For generations we have inspired people to ignite happy connections with friends and family. Let the good times roll with every sip of Maltina.
                </BadgeCopy>
            </BadgeContainer>
        </Section>
    )
}

export default BadgeSection;
