import React from "react";
import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import ButtonOutline from "../buttonOutline";

const Section = styled.section`

`;

const Background = styled(BackgroundImage)`
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 5rem;

    @media (max-width: 60rem) {
        padding: 1rem;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 30rem;
    margin: 1rem 0;

    @media (max-width: 60rem) {
        align-items: center;
        background-color: rgb(0, 0, 0, 0.3);
        padding: 0.5rem;
    }
`;

const Heading = styled.h3`
    color: #fff;
    text-transform: uppercase;
    font-size: 2rem;
    margin-bottom: 0;
    text-align: left;

    @media (max-width: 60rem) {
        text-align: center;
    }
`;

const Copy = styled.p`
    font-size: 1.2rem;
    color: #fff;
    text-align: left;

    @media (max-width: 60rem) {
        text-align: center;
    }
`;

const TeacherSection = () => {
    const data = useStaticQuery(
        graphql`
        query {
          background: file(relativePath: { eq: "sections/teacher-of-the-year.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `
    );

    const backgroundData = data.background.childImageSharp.fluid;

    return (
        <Section id="teacher-of-the-year">
            <Background fluid={backgroundData}>
                <Content>
                    <Heading>
                        Maltina Teacher of the Year
                    </Heading>
                    <Copy>
                        The Maltina Teacher of the Year initiative aims to reconize and celebrate exceptional teachers across Nigeria. The initiative is open to teachers in ALL secondary schools around the country. Each year, you can nominate your amazing and deserving teacher to recognize them for the amazing work they do.
                    </Copy>
                    <ButtonOutline href="https://maltinateacheroftheyear.com/">
                        Learn More
                    </ButtonOutline>
                </Content>
            </Background>
        </Section>
    )
}

export default TeacherSection;
